import React from "react";
import "./Items.scss";

export const Items = ({ item }) => {
	return (
		<div className="item-details">
			<div className="icon">
				<img src={item.icon} alt="icon"></img>
				<h4>{item.title}</h4>
			</div>
			<div className="detail">
				<p>{item.desc}</p>
			</div>
		</div>
	);
};
