import React from "react";
import "./ServiceImage.scss";

import Image1 from "../../assets/images/services/sports/1.png";
import Image2 from "../../assets/images/services/sports/2.png";
import Image3 from "../../assets/images/services/sports/3.png";
import Image4 from "../../assets/images/services/sports/4.png";
import Image5 from "../../assets/images/services/sports/5.png";
import Image6 from "../../assets/images/services/sports/6.png";
import Image7 from "../../assets/images/services/sports/7.png";
import Image8 from "../../assets/images/services/sports/8.png";
import Image9 from "../../assets/images/services/sports/9.png";
import Image10 from "../../assets/images/services/sports/10.png";
import Image11 from "../../assets/images/services/sports/11.png";
import Image12 from "../../assets/images/services/sports/12.png";

export const SportsImage = () => {
	return (
		<div class="row">
			<div class="column">
				<img alt="img" src={Image1} style={{ width: "100%" }} />
				<img alt="img" src={Image5} style={{ width: "100%" }} />
				<img alt="img" src={Image12} style={{ width: "100%" }} />
			</div>
			<div class="column">
				<img alt="img" src={Image7} style={{ width: "100%" }} />
				<img alt="img" src={Image8} style={{ width: "100%" }} />
				<img alt="img" src={Image9} style={{ width: "100%" }} />
			</div>
			<div class="column">
				<img alt="img" src={Image4} style={{ width: "100%" }} />
				<img alt="img" src={Image6} style={{ width: "100%" }} />
				<img alt="img" src={Image10} style={{ width: "100%" }} />
			</div>
			<div class="column">
				<img alt="img" src={Image2} style={{ width: "100%" }} />
				<img alt="img" src={Image3} style={{ width: "100%" }} />
				<img alt="img" src={Image11} style={{ width: "100%" }} />
			</div>
		</div>
	);
};
