import React from "react";
import "./Clients.scss";
import client1 from "../../assets/images/clients/1.png";
import client2 from "../../assets/images/clients/2.png";
import client3 from "../../assets/images/clients/3.png";
import client4 from "../../assets/images/clients/4.png";
import client5 from "../../assets/images/clients/5.png";
import client6 from "../../assets/images/clients/6.png";
import client7 from "../../assets/images/clients/7.png";
import client8 from "../../assets/images/clients/8.png";
import client9 from "../../assets/images/clients/9.png";
import client10 from "../../assets/images/clients/10.png";
import client11 from "../../assets/images/clients/11.png";
import client12 from "../../assets/images/clients/12.png";
import client13 from "../../assets/images/clients/13.png";
import client14 from "../../assets/images/clients/14.png";
import client15 from "../../assets/images/clients/15.png";
import client31 from "../../assets/images/clients/16.png";
import client17 from "../../assets/images/clients/17.png";
import client18 from "../../assets/images/clients/18.png";
import client19 from "../../assets/images/clients/19.png";
import client20 from "../../assets/images/clients/20.png";
import client21 from "../../assets/images/clients/21.png";
import client22 from "../../assets/images/clients/22.png";
import client23 from "../../assets/images/clients/23.png";
import client24 from "../../assets/images/clients/24.png";
import client25 from "../../assets/images/clients/25.png";
import client26 from "../../assets/images/clients/26.png";
import client27 from "../../assets/images/clients/27.png";
import client28 from "../../assets/images/clients/28.png";
import client29 from "../../assets/images/clients/29.png";
import client30 from "../../assets/images/clients/30.png";

export const Clients = () => {
	const clientImg = [
		client1,
		client2,
		client3,
		client4,
		client5,
		client6,
		client7,
		client8,
		client9,
		client10,
		client11,
		client12,
		client13,
		client14,
		client15,
		client31,
		client17,
		client18,
		client19,
		client20,
		client21,
		client22,
		client23,
		client24,
		client25,
		client26,
		client27,
		client28,
		client29,
		client30,
	];

	const images = clientImg.map((item, index) => (
		<React.Fragment key={index}>
			<div className="slide">
				<img src={item} width="150" margin="10" alt="" />
			</div>
		</React.Fragment>
	));

	return (
		<div className="customer-logos">
			<div className="slider">
				<div className="slide-track">{images}</div>
			</div>
		</div>
	);
};
