import React, { useEffect } from "react";
import { Home } from "./pages/home/Home";
import Aos from "aos";

const App = () => {
	useEffect(() => {
		Aos.init();
	}, []);

	return (
		<div className="App">
			<Home />
		</div>
	);
};

export default App;
