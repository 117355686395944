import React from "react";
import "./BottomSection.scss";
import whitelogo from "../../assets/images/whiteweblogo.svg";
import { useNavigate } from "react-router-dom";

const colors = ["#0088FE", "#00C49F", "#FFBB28"];
const delay = 5000;

export const BottomSection = () => {
	let navigate = useNavigate();

	const goToPage = () => {
		navigate(`/service/local`);
		window.scrollTo(0, 0);
	};

	const [index, setIndex] = React.useState(0);
	const timeoutRef = React.useRef(null);

	function resetTimeout() {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
	}

	React.useEffect(() => {
		resetTimeout();
		timeoutRef.current = setTimeout(
			() =>
				setIndex((prevIndex) =>
					prevIndex === colors.length - 1 ? 0 : prevIndex + 1
				),
			delay
		);

		return () => {
			resetTimeout();
		};
	}, [index]);

	return (
		<div className="first-fold-b">
			<div className="bottom-content">
				<div className="left-content">
					<div className="left-sec">
						<div>
							<img src={whitelogo} alt="logo" />
						</div>
						<div className="comp-name">
							<h3>End to end media solutions</h3>
							<div className="comp-text">
								Guided by experience and shaped by technology, Atom Ant Studios
								is on a mission to become the largest localisation company in
								the world.
							</div>
						</div>
					</div>
					<div className="btn-bottom">
						<button className="know-btn" onClick={goToPage}>
							KNOW MORE
						</button>
					</div>
				</div>
				<div className="right-content">
					{/* <div>
						"When Sony Entertainment India wanted to digitize its 2-decade old
						library with tens of thousands of hours of archived content in
						formats that are now extinct, they needed the best in the business
						that would have the ability to collate at one place the entire
						necessary tech infra working in a cohesive manner.
					</div>
					<br />
					<div>
						From degaussing machines to Betacam players and splicing tools, Atom
						Ant managed to put it all together in a matter of weeks and
						subsequently digitized the entire content library of Sony."
					</div>
					<div className="c-name">
						<h4>SONY ENTERTAINMENT</h4>
					</div> */}
					<div className="slideshow">
						<div
							className="slideshowSlider"
							style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
						>
							{/* {colors.map((backgroundColor, index) => (
								<div
									className="slide"
									key={index}
									style={{ backgroundColor }}
								></div>
							))} */}
							<div className="slide">
								<div>
									"When Sony Entertainment India wanted to digitize its 2-decade
								</div>
								<div>
									old library with tens of thousands of hours of archived
								</div>
								<div>
									content in formats that are now extinct, they needed the best
								</div>
								<div>
									in the business that would have the ability to collate at one
								</div>
								<div>
									place the entire necessary tech infra working in a cohesive
								</div>
								<div>manner.</div>
								<br />
								<div>
									From degaussing machines to Betacam players and splicing
								</div>
								<div>
									tools, Atom Ant managed to put it all together in a matter of
								</div>
								<div>
									weeks and subsequently digitized the entire content library of
								</div>




								{/* <div>"When Sony Entertainment India wanted to digitize its 2-decade old
								library with tens of thousands of hours of archived content in
								formats that are now extinct, they needed the best in the business
								that would have the ability to collate at one place the entire
								necessary tech infra working in a cohesive manner."</div> */}



								<div>Sony."</div>
								<div className="c-name">
									<h4>SONY PICTURES NETWORK</h4>
								</div>
							</div>
							<div className="slide">



								<div>
									When Warner Media wanted to subtitle tens of thousands of
								</div>
								<div>
									hours of content across numerous Indian languages within 10
								</div>
								<div>
									months, they approached Atom Ant. This is the stuff of
								</div>
								<div>
									Industry folklore when so much needs to be achieved in so
								</div>
								<div>
									little time! Atom Ant is now executing the same for Warner
								</div>
								<div>
									Media! "When Sony Entertainment India wanted to digitize its
								</div>
								<div>2-decade</div>

								{/* <div>When Warner Media wanted to subtitle tens of thousands of hours of content across numerous Indian languages within 10 months, they approached Atom Ant. This is the stuff of
								Industry folklore when so much needs to be achieved in so little time! Atom Ant is now executing the same for Warner Media! "When Sony Entertainment India wanted to digitize its
								2-decade
								</div> */}




								<br />
								<div className="c-name">
									<h4>WARNER MEDIA</h4>
								</div>
							</div>
							<div className="slide">
								<div>
									When a NSE listed company, Navkar Group decided they wanted to
								</div>
								<div>
									launch a Spiritual channel in India, they approached Atom Ant
								</div>
								<div>
									– to manage everything from procuring the license of the
								</div>
								<div>
									channel to the Production, Distribution and Broadcasting of
								</div>
								<div>
									the same. Atom Ant’s team with a combined experience of more
								</div>
								<div>
									than 100 years is now the sole company managing this on a
								</div>
								<div>turnkey basis.</div>
								<br />
								<div className="c-name">
									<h4>NAVKAR GROUP</h4>
								</div>
							</div>
						</div>
						<div className="slideshowDots">
							{colors.map((_, idx) => (
								<div
									key={idx}
									className={`slideshowDot${index === idx ? " active" : ""}`}
									onClick={() => {
										setIndex(idx);
									}}
								></div>
							))}
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
