import React, { useEffect, useRef } from "react";
import { Header } from "../../components/header/Header";
import "./Home.scss";
import AOS from "aos";
import { About } from "../../components/about/About";
import { Services } from "../../components/services/Services";
import { Title } from "../../components/title/Title";
import { ImageGallery } from "../../components/imageGallery/Image";
import { Team } from "../../components/teams/Team";
import { Counter } from "../../components/counter/Counter";
import { Clients } from "../../components/clients/Clients";
import { MainText } from "../../components/mainText/MainText";
import mainVideo from "../../assets/videos/atom_ant_cover.mp4";
import { Marquee } from "../../components/text-marquee/Marquee";
import { BottomSection } from "../../components/bottom-section/BottomSection";
import { VideoCarousel } from "../../components/video-carousel/Carousel";
import { Footer } from "../../components/footer/Footer";
import "aos/dist/aos.css";
import { BrowserView, MobileView } from "react-device-detect";

export const Home = () => {
	const homeRef = useRef(null);
	const aboutRef = useRef(null);
	const serviceRef = useRef(null);
	const wnyRef = useRef(null);
	const teamRef = useRef(null);

	const executeScroll = (ref) => {
		if (ref === "home") {
			homeRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
		}
		if (ref === "about") {
			aboutRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
		}
		if (ref === "service") {
			serviceRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
		}
		if (ref === "why") {
			wnyRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
		}
		if (ref === "team") {
			teamRef.current.scrollIntoView({ behavior: "smooth", block: "start" });
		}
	};

	useEffect(() => {
		window.history.scrollRestoration = "manual";
		AOS.init();
		AOS.refresh();
	}, []);

	return (
		<React.Fragment>
			<Header onNavClickHandler={executeScroll} />
			<div className="main-container" ref={homeRef}>
				<div className="first-fold">
					<BrowserView>
						<video
							className="main-video"
							autoPlay
							muted
							loop
							poster="../../assets/images/fadelogo.png"
						>
							<source src={mainVideo} type="video/mp4" />
							Your browser does not support the video tag.
						</video>
					</BrowserView>

					<div className="content">
						<MainText />
						<div className="main-text">create. localize. globalize.</div>
					</div>
				</div>
				<div className="second-fold" ref={aboutRef} data-aos="fade-up">
					<About />
				</div>
				<div className="third-fold" ref={serviceRef} data-aos="fade-up">
					<Services />
				</div>
				<div className="marq-sec">
					<Marquee />
				</div>
				{/* <div data-aos="fade-up">
					<CaseStudies />
				</div> */}
				<div className="eight-fold" ref={wnyRef} data-aos="fade-up">
					<BottomSection />
				</div>
				<div className="counter-fold" data-aos="fade-up">
					<Counter />
				</div>
				<div className="sixth-fold" data-aos="fade-up">
					<ImageGallery />
				</div>
				<div className="seventh-fold" ref={teamRef}>
					<div className="bg-color"></div>
					<Title tag="MEET THE" title="TEAM" />
					<Team />
				</div>
				<div className="seventh-fold">
					<div data-aos="fade-up">
						<VideoCarousel />
					</div>
					<div data-aos="fade-up">
						<Clients />
					</div>
				</div>
			</div>
			<Footer />
		</React.Fragment>
	);
};
