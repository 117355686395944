import React from "react";
import "./About.scss";
import video from '../../assets/videos/video-white-bg.mp4'

export const About = () => {
  return (
    <div className="about-content">
       <div className="image">
        <video width="100%" height="500" autoPlay muted loop>
          <source src={video} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <div className="content">
        Atom Ant is born with a vision to transcend global language boundaries
        starting from one of the most diverse nations in the world, India.
      </div>
    </div>
  );
};
