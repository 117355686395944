import React from "react";
import "./ServiceImage.scss";

import Image1 from "../../assets/images/services/prod/1.png";
import Image2 from "../../assets/images/services/prod/2.png";
import Image3 from "../../assets/images/services/prod/3.jpg";
import Image4 from "../../assets/images/services/prod/4.jpg";
import Image5 from "../../assets/images/services/prod/5.jpg";
import Image6 from "../../assets/images/services/prod/6.jpg";
import Image7 from "../../assets/images/services/prod/7.jpg";
import Image8 from "../../assets/images/services/prod/8.jpg";
import Image9 from "../../assets/images/services/prod/9.jpg";
import Image10 from "../../assets/images/services/prod/10.jpg";
import Image11 from "../../assets/images/services/prod/11.jpg";
import Image12 from "../../assets/images/services/prod/12.jpg";

export const ProductionImage = () => {
	return (
		<div class="row">
			<div class="column">
				<img alt="img" src={Image4} style={{ width: "100%" }} />
				<img alt="img" src={Image5} style={{ width: "100%" }} />
				<img alt="img" src={Image6} style={{ width: "100%" }} />
			</div>
			<div class="column">
				<img alt="img" src={Image3} style={{ width: "100%" }} />
				<img alt="img" src={Image2} style={{ width: "100%" }} />
				<img alt="img" src={Image1} style={{ width: "100%" }} />
			</div>
			<div class="column">
				<img alt="img" src={Image7} style={{ width: "100%" }} />
				<img alt="img" src={Image8} style={{ width: "100%" }} />
				<img alt="img" src={Image9} style={{ width: "100%" }} />
			</div>
			<div class="column">
				<img alt="img" src={Image10} style={{ width: "100%" }} />
				<img alt="img" src={Image11} style={{ width: "100%" }} />
				<img alt="img" src={Image12} style={{ width: "100%" }} />
			</div>
		</div>
	);
};
