import "./Carousel.scss";
import video1 from "../../assets/videos/c1.mp4";
import video2 from "../../assets/videos/c4.mp4";
import video3 from "../../assets/videos/c3.mp4";
import video4 from "../../assets/videos/c2.mp4";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";

import { Swiper, SwiperSlide } from "swiper/react";

export const VideoCarousel = () => {
	const links = [video1, video2, video3, video4];
	const videos = [
		{ video: video4, titile: "Ad Subtitling" },
		{ video: video3, titile: "Movie Subtitling" },
		{ video: video1, titile: "Dubbing Showreel" },
	];

	return (
		<Swiper
			// install Swiper modules
			modules={[Navigation, Pagination, Scrollbar, A11y]}
			spaceBetween={50}
			slidesPerView={1}
			navigation
			pagination={{ clickable: true }}
			scrollbar={{ draggable: true }}
			onSwiper={(swiper) => console.log(swiper)}
			onSlideChange={() => console.log("slide change")}
		>
			{videos.map((item, index) => (
				<SwiperSlide key={index}>
					<div className="video-title">{item.titile}</div>
					<video
						autoPlay
						muted
						loop
						poster="../../assets/images/fadelogo.png"
						key={index}
						width="100%"
						height="550px"
					>
						<source src={item.video} type="video/mp4" />
						Your browser does not support the video tag.
					</video>
				</SwiperSlide>
			))}
			...
		</Swiper>
	);
};
