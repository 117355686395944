import React from "react";
import "./Image.scss";
import img5 from "../../assets/images/projects-7.jpg";
import img6 from "../../assets/images/vfx.jpeg";
import img7 from "../../assets/images/projects-4.jpg";
import img8 from "../../assets/images/projects-2-big.jpg";

export const ImageGallery = () => {
	return (
		<div className="services">
			<div className="example">
				<img src={img5} height="500" alt="house" />
				<div className="fadedbox">
					<div className="title text"> SCALE </div>
				</div>
			</div>
			<div className="example">
				<img src={img6} height="500" alt="house" />
				<div className="fadedbox">
					<div className="title text"> TECHNOLOGY </div>
				</div>
			</div>
			<div className="example">
				<img src={img7} height="500" alt="house" />
				<div className="fadedbox">
					<div className="title text"> QUALITY & TAT </div>
				</div>
			</div>
			<div className="example">
				<img src={img8} height="500" alt="house" />
				<div className="fadedbox">
					<div className="title text"> CONTENT SECURITY </div>
				</div>
			</div>
		</div>
	);
};
