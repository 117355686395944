import React from "react";
import vaibhav from "../../assets/images/vaibhav.jpeg";
import rohit from "../../assets/images/rohit.jpeg";
import javed from "../../assets/images/javed.jpeg";
import vivek from "../../assets/images/vivek.png";
import sidharth from "../../assets/images/sidharth.png";
import reema from "../../assets/images/reema.jpeg";
import "./Team.scss";

export const Team = () => {
	return (
		<div className="team-section" data-aos="fade-up">
			<div className="main">
				<div className="profile-card">
					<div className="img">
						<img alt="img" src={vaibhav} />
					</div>
					<div className="caption">
						<h3>VAIBHAV TIWARI</h3>
						<span>CO FOUNDER</span>
						<div className="social-links">
							<a
								target="_blank"
								href="https://www.linkedin.com/in/vaibhav-tiwari-07353a42/"
							>
								<i className="fab fa-linkedin"></i>
							</a>
						</div>
					</div>
				</div>
				<div className="profile-card">
					<div className="img">
						<img alt="img" src={rohit} />
					</div>
					<div className="caption">
						<h3>ROHIT SHIVDASANI</h3>
						<span>CO FOUNDER</span>
						<div className="social-links">
							<a
								target="_blank"
								href="https://www.linkedin.com/in/rohit-shivdasani-a416531a0/"
							>
								<i className="fab fa-linkedin"></i>
							</a>
						</div>
					</div>
				</div>
				<div className="profile-card">
					<div className="img">
						<img alt="img" src={javed} />
					</div>
					<div className="caption">
						<h3>JAVED SHEIKH</h3>
						<span>CREATIVE DIRECTOR</span>
						<div className="social-links">
							<a target="_blank" href="https://www.linkedin.com/in/jaaveds/">
								<i className="fab fa-linkedin"></i>
							</a>
						</div>
					</div>
				</div>
				<div className="profile-card">
					<div className="img">
						<img alt="img" src={vivek} />
					</div>
					<div className="caption">
						<h3>VIVEK TIWARI</h3>
						<span>CEO, Sports & Events</span>
						<div className="social-links">
							<a target="_blank" href="#">
								<i className="fab fa-linkedin"></i>
							</a>
						</div>
					</div>
				</div>
				<div className="profile-card">
					<div className="img">
						<img alt="img" src={sidharth} />
					</div>
					<div className="caption">
						<h3>SIDHARTH RUBAL</h3>
						<span>EXECUTIVE PRODUCER</span>
						<div className="social-links">
							<a target="_blank" href="#">
								<i className="fab fa-linkedin"></i>
							</a>
						</div>
					</div>
				</div>
				<div className="profile-card">
					<div className="img">
						<img alt="img" src={reema} />
					</div>
					<div className="caption">
						<h3>REEMA SURI</h3>
						<span>HEAD (LOCALISATION)</span>
						<div className="social-links">
							<a target="_blank" href="https://www.linkedin.com/in/reema-suri/">
								<i className="fab fa-linkedin"></i>
							</a>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
