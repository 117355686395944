import React, { useEffect } from "react";
import "./ServiceImage.scss";

export const ServiceImage = ({ item }) => {
	useEffect(() => {
		// setchange(true);
	}, [item]);

	return (
		<div className="example">
			<img id="img" src={item.img} alt="house" />
			<div className="fadedbox-s">
				<div className="title text"> {item.name} </div>
			</div>
		</div>
	);
};
