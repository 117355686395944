import Image1 from "../../assets/images/services/s1.jpg";
import Image2 from "../../assets/images/services/s2.jpg";
import Image3 from "../../assets/images/services/s3.jpg";
import Image4 from "../../assets/images/services/s4.jpg";
import Image5 from "../../assets/images/services/s5.jpg";
import Image6 from "../../assets/images/services/s6.jpg";
import Image7 from "../../assets/images/services/s7.jpg";
import Image8 from "../../assets/images/services/s8.jpg";
import ImageMain1 from "../../assets/images/services/local.jpg";
import ImageMain2 from "../../assets/images/services/prod.png";
import ImageMain3 from "../../assets/images/services/vfx.jpg";
import ImageMain4 from "../../assets/images/services/sports.png";
import Icon1 from "../../assets/images/icons/subtitle.png";
import Icon2 from "../../assets/images/icons/adaptation.png";
import Icon3 from "../../assets/images/icons/adjustment.png";
import Icon4 from "../../assets/images/icons/captioning.png";
import Icon5 from "../../assets/images/icons/confirm.png";
import Icon6 from "../../assets/images/icons/notation.png";
import Icon7 from "../../assets/images/icons/pre_production.png";
import Icon8 from "../../assets/images/icons/production.png";
import Icon9 from "../../assets/images/icons/sports_and_live_event.png";
import Icon10 from "../../assets/images/icons/producticon.png";
import Icon11 from "../../assets/images/icons/brand.png";
import Icon12 from "../../assets/images/icons/corporates.png";
import Icon13 from "../../assets/images/icons/broadcast.png";

export const serviceInfo = {
	image: ImageMain1,
	title: "Localisation Services: Content Localisation and Versioning",
	desc: "Atom Ant Studios is home to a team of experts carrying diverse experience in post-production processes including content localisation ranging from subtitling to adaptations and dubbing & mixing.",
};
export const serviceInfo2 = {
	image: ImageMain2,
	title: "Production Services: Content Production",
	desc: "Atom Ant Studios comprises of a team of industry experts handling end to end creative services for TVCs, on air promo, branded content and film trailers",
};
export const serviceInfo3 = {
	image: ImageMain3,
	title: "Broadcast & Media Services",
	desc: "Founded by a team with a strong background in various areas of the media fraternity, Atom Ant Studios excels in broadcast media services, having been a part of Star News’ launch, Bloomberg TV, & Times Now among other channels. As part of its latest project, Atom Ant Studios is prepped to roll out a niche spiritual channel named Chaitanya TV.",
	desc1:
		"Atom Ant Studios is home to a strong team leveraging cutting-edge technologies to undertake and execute end-to-end broadcast channel services from creative films to sports and entertainment.",
	desc2:
		"The team works with a wide range of clients from brands to products and corporates in channel operations, distributions or digital business allowing the efficient management of content through seamless workflow.",
};
export const serviceInfo4 = {
	image: ImageMain4,
	title: "Sports and Live Events",
	desc: "Atom Ant Studios offers a complete range of audio, video, and lighting gear along with highly skilled technicians to enable live large-scale Multicam production coverage of sports and events. Our creative design servicemen are well-experienced in conceptualising and production of format-based IPs/events across media.",
};

export const ServiceDetails = [
	{
		icon: Icon1,
		title: "Subtitling",
		desc: "Our in-house team of linguists and language experts work with precision to deliver contents that are grammatically accurate.",
	},
	{
		icon: Icon4,
		title: "SDH/Closed Captioning",
		desc: "With closed captioning, we help to include more viewers enjoy top-quality content.",
	},
	{
		icon: Icon5,
		title: "Conforming",
		desc: "Matching source time code to the subtitles provided is termed as Conforming or syncing the files.",
	},
	{
		icon: Icon6,
		title: "Transcriptions/Trans-creation",
		desc: "Transcription in the linguistic sense is the systematic representation of spoken language in written form.",
	},
	{
		icon: Icon3,
		title: "Dubbing & Mixing",
		desc: "Dubbing artists and directors at Atom Ant Studios are flexible with any language, genre, or format.",
	},
	{
		icon: Icon2,
		title: "Adaptations",
		desc: "Adaptation is a translation method that focuses on altering the source text so that it corresponds to the culture, style, grammar, semantics and syntax of the target language. ",
	},
];
export const ServiceDetails2 = [
	{
		icon: Icon7,
		title: "Pre-Production",
		desc: "This is the first step of the magic that we create! Our team listens carefully and develops a celluloid concept",
	},
	{
		icon: Icon8,
		title: "Production",
		desc: "We have an in-house team of directors and producers who are well-versed in handling Multi-cam",
	},
	{
		icon: Icon8,
		title: "Post-Production",
		desc: "With our strong experience in post-production services, our renowned Creative and Technical talents ensure to deliver beyond project requirements working hand in hand with clients for customized packages.",
	},
	{
		icon: Icon9,
		title: "Sports and Live Events",
		desc: "Atom Ant Studios offers a complete range of audio, video, and lighting gear along with highly skilled technicians to enable live large-scale Multicam production coverage of sports and events.",
	},
];

export const ServiceDetails3 = [];

export const ServiceDetails4 = [];

export const ServiceItems = [];

export const ServiceItems2 = [
	{
		icon: Icon13,
		title: "Broadcasters",
		desc: "IPs, shows, events, on-air promotions (OAP), sports entertainment, sports IP, sales communication",
	},
	{
		icon: Icon11,
		title: "Brands",
		desc: "Brand Campaigns, Umbrella Campaigns, Offer centric properties, Festive occasions, Awareness Campaigns, Milestones, Affiliate/Partners, Influencers/Celeb Tie-ups ",
	},
	{
		icon: Icon10,
		title: "Products",
		desc: "Informative/Instructional, Product/Feature Centric, Interactive Product, Launch, How to/Demos, Catalogue, Comparison, Special Offers, VR/AR",
	},
	{
		icon: Icon12,
		title: "Corporates",
		desc: "Micro Documentaries, Culture/Tour/HR, Interviews/Testimonials, Informative/Vision/History/Leadership, Event Coverage, Sales Communication, Internal Communication, CSR",
	},
];

export const ServiceItems3 = [];

export const ServiceItems4 = [];

export const ServiceImageGalery = [
	{
		img: Image1,
		name: "Culture",
	},
	{
		img: Image2,
		name: "Grammar",
	},
	{
		img: Image3,
		name: "Semantics",
	},
	{
		img: Image4,
		name: "Accuracy",
	},
];

export const ServiceImageGalery2 = [
	{
		img: Image5,
		name: "Scripting",
	},
	{
		img: Image6,
		name: "Storyboarding",
	},
	{
		img: Image7,
		name: "Ideation",
	},
	{
		img: Image8,
		name: "Localisation",
	},
];

export const ServiceImageGalery3 = [
	{
		img: Image1,
		name: "Technology",
	},
	{
		img: Image2,
		name: "Personnel",
	},
	{
		img: Image3,
		name: "Security",
	},
	{
		img: Image4,
		name: "Scale",
	},
];

export const ServiceImageGalery4 = [
	{
		img: Image5,
		name: "Multicam",
	},
	{
		img: Image6,
		name: "Audio",
	},
	{
		img: Image7,
		name: "Video",
	},
	{
		img: Image8,
		name: "Lighting gear",
	},
];
