import React from "react";
import "./ServiceImage.scss";

import Image1 from "../../assets/images/services/local/1.png";
import Image2 from "../../assets/images/services/local/2.jpg";
import Image3 from "../../assets/images/services/local/3.jpg";
import Image4 from "../../assets/images/services/local/4.jpg";
import Image5 from "../../assets/images/services/local/5.png";
import Image6 from "../../assets/images/services/local/6.jpg";
import Image7 from "../../assets/images/services/local/7.png";
import Image8 from "../../assets/images/services/local/8.jpg";
import Image9 from "../../assets/images/services/local/9.jpg";
import Image10 from "../../assets/images/services/local/10.jpg";
import Image11 from "../../assets/images/services/local/11.jpg";
import Image13 from "../../assets/images/services/local/13.jpg";
import Image14 from "../../assets/images/services/local/14.jpg";
import Image15 from "../../assets/images/services/local/15.jpg";
import Image16 from "../../assets/images/services/local/16.jpg";

export const Localmage = () => {
	return (
		<div class="row">
			<div class="column">
				<img alt="img" src={Image1} style={{ width: "100%" }} />
				<img alt="img" src={Image2} style={{ width: "100%" }} />
				<img alt="img" src={Image3} style={{ width: "100%" }} />
				<img alt="img" src={Image4} style={{ width: "100%" }} />
			</div>
			<div class="column">
				<img alt="img" src={Image5} style={{ width: "100%" }} />
				<img alt="img" src={Image6} style={{ width: "100%" }} />
				<img alt="img" src={Image7} style={{ width: "100%" }} />
				<img alt="img" src={Image8} style={{ width: "100%" }} />
			</div>
			<div class="column">
				<img alt="img" src={Image9} style={{ width: "100%" }} />
				<img alt="img" src={Image10} style={{ width: "100%" }} />
				<img alt="img" src={Image11} style={{ width: "100%" }} />
			</div>
			<div class="column">
				<img alt="img" src={Image13} style={{ width: "100%" }} />
				<img alt="img" src={Image14} style={{ width: "100%" }} />
				<img alt="img" src={Image15} style={{ width: "100%" }} />
				<img alt="img" src={Image16} style={{ width: "100%" }} />
			</div>
		</div>
	);
};
