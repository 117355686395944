import React from "react";
import "./Footer.scss";
import { useNavigate } from "react-router-dom";

export const Footer = () => {
	let navigate = useNavigate();

	const goToPage = (key) => {
		navigate(`/service/${key}`);
		window.scrollTo(0, 0);
	};

	const goToContact = () => {
		navigate(`/contact-us`);
		window.scrollTo(0, 0);
	};

	return (
		<div>
			<div class="footer-dark">
				<footer>
					<div class="row">
						<div class="item">
							<h3>Services</h3>
							<ul>
								<li>
									<a onClick={() => goToPage("local")}>LOCALISATION SERVICES</a>
								</li>
								<li>
									<a onClick={() => goToPage("prod")}>PRODUCTION SERVICES</a>
								</li>
								<li>
									<a onClick={() => goToPage("broadcast")}>
										BROADCAST & MEDIA SERVICES
									</a>
								</li>
								<li>
									<a onClick={() => goToPage("sports")}>
										SPORTS AND LIVE EVENTS SERVICES
									</a>
								</li>
							</ul>
						</div>
						<div class="item">
							<h3>About</h3>
							<ul>
								<li>
									<a href="#">Company</a>
								</li>
								<li>
									<a href="#">Team</a>
								</li>
								<li>
									<a onClick={() => goToContact()}>Contact us</a>
								</li>
								<li>
									<a href="https://www.linkedin.com/company/atom-ant-productions/about">
										Careers
									</a>
								</li>
							</ul>
						</div>
						<div class="item f-text">
							<h3>Atom Ant Studios</h3>
							<p>Knox Plaza, Near Tangent Showroom,</p>
							<p>Malad, Mindspace, Malad West</p>
							<p>Mumbai, Maharashtra, 400064</p>
						</div>
						<div class="col item social"></div>
					</div>
				</footer>
				<p class="copyright">Atom Ant Studios © 2022</p>
			</div>
		</div>
	);
};
