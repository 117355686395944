import React from "react";
import "./Services.scss";
import { useNavigate } from "react-router-dom";

export const Services = () => {
	const style = {
		margin: "0 auto",
		width: "100%",
	};
	let navigate = useNavigate();

	const goToPage = (key) => {
		navigate(`/service/${key}`);
		window.scrollTo(0, 0);
	};

	return (
		<div className="swiper-container">
			<div className="swiper-wrapper">
				<div className="swiper-slide">
					<div style={style}>
						<div className="gallery-wrap wrap-effect">
							<div className="image-item">
								<div className="content">
									<h4 onClick={() => goToPage("local")}>
										LOCALISATION SERVICES
									</h4>
									<div className="c-desc">
										Atom Ant Studios is home to a team of experts carrying
										diverse experience in post-production processes including
										content localisation ranging from subtitling to adaptations
										and dubbing & mixing.
									</div>
									<button
										className="know-more-btn"
										onClick={() => goToPage("local")}
									>
										KNOW MORE
									</button>
								</div>
							</div>
							<div className="image-item">
								<div className="content">
									<h4 onClick={() => goToPage("prod")}>PRODUCTION SERVICES</h4>
									<div className="c-desc">
										Production activities at Atom Ant Studios include handling
										end to end creative services for TVCs, on air promo, branded
										content and film trailers.
									</div>
									<button
										className="know-more-btn"
										onClick={() => goToPage("prod")}
									>
										KNOW MORE
									</button>
								</div>
							</div>
							<div className="image-item">
								<div className="content">
									<h4 onClick={() => goToPage("broadcast")}>
										BROADCAST & MEDIA SERVICES
									</h4>
									<div className="c-desc">
										A strong team leveraging cutting-edge technologies to
										undertake digitization and execute both the setup and
										management of channels.
									</div>
									<button
										className="know-more-btn"
										onClick={() => goToPage("broadcast")}
									>
										KNOW MORE
									</button>
								</div>
							</div>
							<div className="image-item">
								<div className="content">
									<h4 onClick={() => goToPage("sports")}>
										SPORTS AND LIVE EVENTS SERVICES
									</h4>
									<div className="c-desc">
										Atom Ant Studios offers a complete range of audio, video,
										and lighting gear along with highly skilled technicians to
										enable live large-scale Multicam production coverage of
										sports and events.
									</div>
									<button
										className="know-more-btn"
										onClick={() => goToPage("sports")}
									>
										KNOW MORE
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
