import React, { useState, useEffect } from "react";
import { Header } from "../../components/header/Header";
import "./Service.scss";
import { Details } from "./service-details/Details";
import { Items } from "./service-items/Items";
import {
	serviceInfo,
	serviceInfo2,
	serviceInfo3,
	serviceInfo4,
	ServiceDetails,
	ServiceDetails2,
	ServiceDetails3,
	ServiceDetails4,
	ServiceImageGalery,
	ServiceImageGalery2,
	ServiceImageGalery3,
	ServiceImageGalery4,
	ServiceItems,
	ServiceItems2,
	ServiceItems3,
	ServiceItems4,
} from "../../data/services-content/Services";
import { ServiceImage } from "../../components/service-image/ServiceImage";
import "aos/dist/aos.css";
import AOS from "aos";
import { Localmage } from "../../components/service-image/Localmage";
import { BroadcastImage } from "../../components/service-image/BroadcastImage";
import { SportsImage } from "../../components/service-image/SportsImage";
import { ProductionImage } from "../../components/service-image/ProductionImage";
import { Footer } from "../../components/footer/Footer";

import { useLocation } from "react-router-dom";

export const Service = () => {
	const [images, setImages] = useState([]);
	const [serviceItems, setServiceItems] = useState([]);
	const [serviceDetails, setServiceDetails] = useState([]);
	const [info, setInfo] = useState({});
	const [selected, setSelected] = useState(1);
	const [fade, setFade] = useState(false);
	const location = useLocation();

	useEffect(() => {
		AOS.init();
		AOS.refresh();
		getData();
	}, []);

	const getData = () => {
		if (location.pathname.includes("service/local")) {
			getDetails(1);
		} else if (location.pathname.includes("service/prod")) {
			getDetails(2);
		} else if (location.pathname.includes("service/broadcast")) {
			getDetails(3);
		} else {
			getDetails(4);
		}
	};

	const serviceDetail = serviceDetails.map((item, index) => {
		return (
			<div className="service" key={index}>
				<Details item={item} />
			</div>
		);
	});
	const serviceItem = serviceItems.map((item, index) => {
		return (
			<div className="our-service" key={index}>
				<Items item={item} />
			</div>
		);
	});

	const imageGalery = images.map((item, index) => {
		return <ServiceImage item={item} key={index} />;
	});

	useEffect(() => {}, [imageGalery]);

	const getDetails = async (item) => {
		if (item === 1) {
			fadeDiv();
			await AOS.refresh();
			await setSelected(1);
			await setImages(ServiceImageGalery);
			await setServiceDetails(ServiceDetails);
			await setServiceItems(ServiceItems);
			await setInfo(serviceInfo);
			return;
		}
		if (item === 2) {
			fadeDiv();
			await AOS.refresh();
			await setSelected(2);
			await setImages(ServiceImageGalery2);
			await setServiceDetails(ServiceDetails2);
			await setServiceItems(ServiceItems2);
			await setInfo(serviceInfo2);
			return;
		}
		if (item === 3) {
			fadeDiv();
			await AOS.refresh();
			await setSelected(3);
			await setImages(ServiceImageGalery3);
			await setServiceDetails(ServiceDetails3);
			await setServiceItems(ServiceItems3);
			await setInfo(serviceInfo3);
		}
		if (item === 4) {
			fadeDiv();
			await AOS.refresh();
			await setSelected(4);
			await setImages(ServiceImageGalery4);
			await setServiceDetails(ServiceDetails4);
			await setServiceItems(ServiceItems4);
			await setInfo(serviceInfo4);
		}
	};

	const fadeDiv = () => {
		setFade(false);
		setInterval(() => {
			setFade(true);
		}, 0);
	};

	return (
		<div>
			<Header />
			<div className="service-container">
				<div className="s-head">
					<div className="s-title">here to create a legacy</div>
				</div>

				{/* <div className="s-title">
					Haswell is a San Francisco based branding & design agency
				</div> */}
				<div className="our-services">
					<div className="s-title-head">
						{/* <div className="os-title">Our Services</div> */}
						<div className="os-filter">
							<div
								className={selected === 1 ? "active" : "filter"}
								onClick={() => getDetails(1)}
							>
								Localisation
							</div>
							<div
								className={selected === 2 ? "active" : "filter"}
								onClick={() => getDetails(2)}
							>
								Production
							</div>
							<div
								className={selected === 3 ? "active" : "filter"}
								onClick={() => getDetails(3)}
							>
								Broadcast
							</div>
							<div
								className={selected === 4 ? "active" : "filter"}
								onClick={() => getDetails(4)}
							>
								SPORTS
							</div>
						</div>
					</div>
				</div>
				<div className="s-details">
					<div className="img" data-aos="fade-right" data-aos-duration="1000">
						<img src={info.image} alt="img" />
					</div>
					<div
						className="service-details"
						data-aos="fade-left"
						data-aos-duration="1000"
					>
						<div className="title">{info.title}</div>
						<div className="desc">{info.desc}</div>
						<br></br>
						<div className="desc">{info?.desc1}</div>
						<div className="desc">{info?.desc2}</div>
						<div className="desc">{info?.desc3}</div>
						<div className="service-d">{serviceDetail}</div>
					</div>
				</div>
				{serviceItem.length ? (
					<div className="our-service-items">{serviceItem}</div>
				) : null}
				<div className={fade ? "fade-div s-images" : "s-images"}>
					{selected === 1 ? (
						<Localmage />
					) : selected === 2 ? (
						<ProductionImage />
					) : selected === 3 ? (
						<BroadcastImage />
					) : (
						<SportsImage />
					)}
				</div>
			</div>
			<Footer />
		</div>
	);
};
