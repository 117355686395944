import React from "react";
import "./Contact.scss";
import map from "../../assets/images/map.png";
import { Header } from "../../components/header/Header";
import { Footer } from "../../components/footer/Footer";
export const Contact = () => {
	return (
		<div>
			<Header />
			<section id="contact">
				<h1 className="section-header">Contact</h1>
				<div className="contact-wrapper">
					<div className="map">
						<a href="https://www.google.com/maps/search/Knox+Plaza,+Near+Tangent+Showroom,+Malad,+Mindspace,+Malad+West,+Mumbai,+Maharashtra,+400064/@19.1767765,72.8339493,18.72z">
							<img src={map} alt="map" />
						</a>
					</div>
					<div className="direct-contact-container">
						<ul className="contact-list">
							<li className="list-item">
								<i className="fa fa-map-marker fa-2x">
								</i>
								<div className="contact-text place">
									Knox Plaza, Near Tangent Showroom, Malad,
									Mindspace, Malad West, Mumbai, Maharashtra, 400064
								</div>
							</li>
						</ul>
						<hr />
						<ul className="contact-list">
							<li className="list-item">
								<i className="fa fa-user fa-2x">
									<span className="contact-text place">Rohit Shivdasani</span>
								</i>
							</li>

							<li className="list-item">
								<i className="fa fa-phone fa-2x">
									<span className="contact-text phone">
										<a href="tel:+91 99870 57610" title="Give me a call">
											+91 99870 57610
										</a>
									</span>
								</i>
							</li>

							<li className="list-item">
								<i className="fa fa-envelope fa-2x">
									<span className="contact-text gmail">
										<a
											href="mailto:rohit@atomantstudios.com"
											title="Send me an email"
										>
											rohit@atomantstudios.com
										</a>
									</span>
								</i>
							</li>
						</ul>
						<hr />

						<ul className="contact-list">
							<li className="list-item">
								<i className="fa fa-user fa-2x">
									<span className="contact-text place">Sidharth Rubal</span>
								</i>
							</li>

							<li className="list-item">
								<i className="fa fa-phone fa-2x">
									<span className="contact-text phone">
										<a href="tel:+91 98200 49613" title="Give me a call">
											+91 99710 39917
										</a>
									</span>
								</i>
							</li>

							<li className="list-item">
								<i className="fa fa-envelope fa-2x">
									<span className="contact-text gmail">
										<a
											href="mailto:vaibhav@atomantstudios.com"
											title="Send me an email"
										>
											sidharth@atomantstudios.com
										</a>
									</span>
								</i>
							</li>
						</ul>
						<hr />

						<div className="copyright">&copy; ALL OF THE RIGHTS RESERVED</div>
					</div>
				</div>
			</section>
			<Footer />
		</div>
	);
};
