import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from "./pages/home/Home";
import App from "./App";
import { Service } from "./pages/services/Service";
import { Contact } from "./pages/contact/Contact";

const Routing = () => {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<App />}>
					<Route index element={<Home />} />
					<Route path="service" element={<Service />}>
						{/* <Route path=":name" element={<Service />} /> */}
					</Route>
				</Route>
				<Route path="service/:name" element={<Service />} />
				<Route path="contact-us" element={<Contact />} />
			</Routes>
		</BrowserRouter>
	);
};

export default Routing;
