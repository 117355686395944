import React from 'react'
import './Title.scss'

export const Title = ({tag, title}) => {
  return (
    <h2 className="sec-title">
      {tag} <span>{title}</span>
    </h2>
  )
}
