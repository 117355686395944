import React, { useEffect, useState } from "react";
import "./Header.scss";
import logo from "../../assets/images/black_logo.svg";
import menu from "../../assets/images/menu.svg";
import whitelogo from "../../assets/images/whiteweblogo.svg";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export const Header = ({ onNavClickHandler }) => {
	const [small, setSmall] = useState(false);

	const location = useLocation();
	let navigate = useNavigate();

	const goToPage = () => {
		navigate(`/`);
		window.scrollTo(0, 0);
	};

	const goToServices = () => {
		navigate(`/service/local`);
	};
	const goToContact = () => {
		navigate(`/contact-us`);
	};

	const menuList = () =>{
		const navList = document.getElementById("navlist");
		if (navList.style.display === "block") {
			navList.style.display = "none";
		  } else {
			navList.style.display = "block";
		  }
	}

	useEffect(() => {
		if (typeof window !== "undefined") {
			window.addEventListener("scroll", () =>
				setSmall(window.pageYOffset > 500)
			);
		}
	}, []);

	const clickHandler = (nav) => {
		if (location.pathname.includes("service/")) {
			goToPage();
			window.scrollTo(0, 0);
		} else {
			onNavClickHandler(nav);
		}
	};

	return (
		<header className={`header ${small ? "small" : ""}`}>
			<div className="logo" onClick={goToPage}>
				<img src={small ? logo : whitelogo} alt="logo"></img>
			</div>
			<div className={`header ${small ? "smallnav" : "navigation"} navlist`} id="navlist">
				<div onClick={() => goToPage()}>Home</div>
				<div onClick={() => clickHandler("about")}>About</div>
				<div onClick={() => goToServices()}>Services</div>
				<div onClick={() => goToContact()}>Contact us</div>
				<div onClick={() => clickHandler("team")}>Team</div>
			</div>
			<a className="menu_icon">
			   <img src={menu} alt="logo" className="icon" id="menuIcon" width="30" onClick={()=> menuList()}></img>
			</a>
		</header>
	);
};
