import React from "react";
import "./Details.scss";

export const Details = ({ item }) => {
	return (
		<div className="details">
			<div className="icon">
				{item?.icon ? <img src={item.icon} alt="icon"></img> : null}
			</div>
			<div className="detail">
				<h4>{item.title}</h4>
				<p>{item.desc}</p>
			</div>
		</div>
	);
};
