import React, { useEffect, useState } from "react";
import "./Marquee.scss";

export const Marquee = () => {
	const [showElement, setShowElement] = useState(false);
	const [show, setShow] = useState(false);

	useEffect(() => {
		setTimeout(function () {
			setShowElement(true);
		}, 6000);
		setTimeout(function () {
			setShow(true);
		}, 3000);
	}, []);

	return (
		<div className="m-text">
			<marquee width="100%" direction="left" height="50px" scrollamount="10">
				English हिन्दी 中国人 português Española اردو عربي தமிழ் Tiếng Việt
				മലയാളം తెలుగు čeština français પસાર नेपाली עִברִית‎ فارسی македонски
				Türk Maori ქართული Ελληνικά o'zbek Hausa norsk پښتو‎‎ English हिन्दी 中国人 português Española اردو عربي தமிழ் Tiếng Việt
				മലയാളം తెలుగు čeština français પસાર नेपाली עִברִית‎ فارسی македонски
				Türk Maori ქართული Ελληνικά o'zbek Hausa norsk پښتو‎‎
			</marquee>
			{/* <div>
				{show ? (
					<marquee
						width="100%"
						direction="right"
						height="50px"
						scrollamount="20"
					>
						English हिन्दी 中国人 português Española اردو عربي தமிழ் Tiếng Việt
						മലയാളം తెలుగు čeština français પસાર नेपाली עִברִית‎ فارسی македонски
						Türk Maori ქართული Ελληνικά o'zbek Hausa norsk پښتو‎
					</marquee>
				) : (
					<></>
				)}
				{showElement ? (
					<marquee
						width="100%"
						direction="left"
						height="50px"
						scrollamount="15"
					>
						English हिन्दी 中国人 português Española اردو عربي தமிழ் Tiếng Việt
						മലയാളം తెలుగు čeština français પસાર नेपाली עִברִית‎ فارسی македонски
						Türk Maori ქართული Ελληνικά o'zbek Hausa norsk پښتو
					</marquee>
				) : (
					<></>
				)}
			</div> */}
		</div>
	);
};
