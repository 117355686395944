import React from "react";
import "./MainText.scss";

export const MainText = () => {
	return (
		<div className="textcontent">
			<p className="textcontent_text">POWER UP WITH</p>
			<div className="textcontent__container">
				<ul className="textcontent__container__list">
					<li className="textcontent__container__list__item">ATOMANT</li>
					<li className="textcontent__container__list__item">啊 安踏</li>
					<li className="textcontent__container__list__item">נמלת אטום‎</li>
					<li className="textcontent__container__list__item">ایٹم انت</li>
				</ul>
			</div>
		</div>
	);
};
