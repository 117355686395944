import React from "react";
import "./Counter.scss";
import CountUp from "react-countup";

export const Counter = () => {
	return (
		<div className="container">
			<div className="item">
				<div className="count-number">
					<CountUp end={12} />
					<span>+</span>
				</div>
				<div className="count-descr">
					<span className="count-title">AWARDS WON</span>
					<div className="text">
						<div className="text__container">
							<ul className="text__container__list">
								<li className="text__container__list__item">MANUIA FAIAIGA</li>
								<li className="text__container__list__item">所獲獎項</li>
								<li className="text__container__list__item">ایوارډونه وګټل</li>
								<li className="text__container__list__item">수상</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="item">
				<div className="count-number">
					<CountUp end={200} />
					<span>+</span>
				</div>
				<div className="count-descr">
					<span className="count-title">HAPPY CLIENTS</span>
					<div className="text">
						<div className="text__container">
							<ul className="text__container__list">
								<li className="text__container__list__item">CLIAINT SHASTA</li>
								<li className="text__container__list__item">खुश ग्राहक</li>
								<li className="text__container__list__item">
									CLIENTES FELIZES
								</li>
								<li className="text__container__list__item">ખુશ ગ્રાહકો</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="item">
				<div className="count-number">
					<CountUp end={550} />
					<span>+</span>
				</div>
				<div className="count-descr">
					<span className="count-title">PROJECTS COMPLETED</span>
					<div className="text">
						<div className="text__container">
							<ul className="text__container__list">
								<li className="text__container__list__item">
									プロジェクトが完了しました
								</li>
								<li className="text__container__list__item">
									પ્રોજેક્ટ્સ પૂર્ણ થયા
								</li>
								<li className="text__container__list__item">
									منصوبے مکمل ہو گئے۔
								</li>
								<li className="text__container__list__item">
									ANNUUM COMPLETED
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
			<div className="item">
				<div className="count-number">
					<CountUp end={75} />
					<span>K+</span>
				</div>
				<div className="count-descr">
					<span className="count-title">HRS OF CONTENT PROCESSED</span>
					<div className="text">
						<div className="text__container">
							<ul className="text__container__list">
								<li className="text__container__list__item">
									処理されたコンテンツ
								</li>
								<li className="text__container__list__item">처리된 콘텐츠</li>
								<li className="text__container__list__item">
									CONTENIDO PROCESADO
								</li>
								<li className="text__container__list__item">
									NỘI DUNG ĐÃ XỬ LÝ
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};
